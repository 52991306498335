<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedPointId"
                    :items="availablePoints"
                    item-text="name"
                    item-value="id"
                    :label="$t(`common['Select Point Setting']`)"
                    :disabled="isActionDisabled"
                    @change="getPointPeriods(selectedPointId, false, false)"
                    :no-data-text="$t(`common['No Data']`)"
                  >
                    <!-- <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon>
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template> -->
                    <!-- <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectedStores.length - 1 }} others)
                  </span>
                </template> -->
                  </v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedPeriodId"
                    :items="availablePeriods"
                    item-text="name"
                    item-value="id"
                    :label="$t(`common['Select Period']`)"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="isActionDisabled"
                    :no-data-text="$t(`common['No Data']`)"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="selectedPeriodId = null">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-select
                    v-model="selectedOrganizationId"
                    :items="availableOrganizations"
                    item-text="organizationName"
                    item-value="organizationId"
                    :label="$t(`common['Select Organization']`)"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="
                      !permissionScope.organization || isActionDisabled
                    "
                    :no-data-text="$t(`common['No Data']`)"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="selectedOrganizationId = null"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(`common['Nothing to select']`) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="getBudgets($event, null, null, true)"
                      :disable="!isPeriodsLoaded"
                      :loading="!isBudgetsLoaded"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              期別預算列表
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="$t(`common['Search']`)"
                single-line
                hide-details
              ></v-text-field>
              <span class="pt-5" v-if="canEdit">
                <v-btn
                  class="
                    font-weight-normal
                    text-capitalize
                    btn-primary
                    bg-gradient-secondary
                    py-3
                    px-6
                    ms-3
                  "
                  @click="onEditItem(null)"
                >
                  {{ $t(`common["Add"]`) }}
                </v-btn>
              </span>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="tableData"
                :loading="!isBudgetsLoaded"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :search="search"
                :sort-by="['id']"
                :sort-desc="[true]"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
                <template v-slot:item.allocatedPoints="{ item }">
                  <span>{{ formatNumber(item.allocatedPoints, 0) }}</span>
                </template>
                <template v-slot:item.availablePoints="{ item }">
                  <span>{{ formatNumber(item.availablePoints, 0) }}</span>
                </template>
                <template v-slot:item.usedPoints="{ item }">
                  <span>{{ formatNumber(item.usedPoints, 0) }}</span>
                </template>
                <template v-slot:item.actualPoints="{ item }">
                  <span>{{ formatNumber(item.actualPoints, 0) }}</span>
                </template>
                <!-- <template v-slot:item.actualPoints="props">
                  <span>{{
                    formatNumber(
                      props.item.allocatedPoints + props.item.transferInPoints,
                      0
                    )
                  }}</span>
                </template> -->
                <template v-slot:item.reviewStatus="{ item }">
                  <span v-if="item.reviewStatus === 0"> 審核中 </span>
                  <span v-else-if="item.reviewStatus === 1"> 核准 </span>
                  <span v-else> 退回 </span>

                  <v-tooltip bottom v-if="canAudit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="teal"
                        v-on="on"
                        :disabled="item.reviewStatus !== 0"
                        v-if="item.reviewStatus === 0"
                        @click="onApproveBudgetClick(item)"
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>審核預算</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.executionRate="props">
                  <span
                    v-if="
                      props.item.allocatedPoints || props.item.transferInPoints
                    "
                  >
                    {{
                      (
                        (props.item.usedPoints /
                          (props.item.allocatedPoints +
                            props.item.transferInPoints)) *
                        100
                      ).toFixed(2) + "%"
                    }}
                  </span>
                  <span v-else> 0.00% </span>
                </template>
                <template v-slot:item.startTs="{ item }">
                  <span>{{
                    item.startTs
                      ? moment.unix(item.startTs).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.endTs="{ item }">
                  <span>{{
                    item.endTs
                      ? moment.unix(item.endTs).format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.createdAt="{ item }">
                  <span>{{
                    item.createdAt
                      ? moment
                          .unix(item.createdAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                  <span>{{
                    item.updatedAt
                      ? moment
                          .unix(item.updatedAt)
                          .format("YYYY/MM/DD HH:mm:ss")
                      : ""
                  }}</span>
                </template>

                <template v-slot:item.actions="props">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="blue darken-2"
                        v-on="on"
                        @click="gotoBudgetsPage(props.item)"
                      >
                        <v-icon>fas fa-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>查看單位預算</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="canEdit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.reviewStatus === 0 ? 'cyan' : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.reviewStatus === 0
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.reviewStatus === 0
                            ? onEditItem(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-edit</v-icon>
                      </v-btn>
                    </template>
                    <span>編輯</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        color="pink darken-2"
                        v-on="on"
                        @click="
                          selectedBudgetId = props.item.id;
                          openDetailDialog = true;
                        "
                      >
                        <v-icon>fas fa-bars</v-icon>
                      </v-btn>
                    </template>
                    <span>查看收支明細</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="canAudit">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-2 mt-2"
                        dark
                        fab
                        x-small
                        :color="
                          props.item.reviewStatus === 1 &&
                          props.item.closeStatus === 0
                            ? 'purple darken-2'
                            : '#DBDCDC'
                        "
                        :style="{
                          cursor:
                            props.item.reviewStatus === 1 &&
                            props.item.closeStatus === 0
                              ? 'pointer'
                              : 'not-allowed',
                        }"
                        v-on="on"
                        @click="
                          props.item.reviewStatus === 1 &&
                          props.item.closeStatus === 0
                            ? onBudgetTransferClick(props.item)
                            : ''
                        "
                      >
                        <v-icon>fas fa-exchange-alt</v-icon>
                      </v-btn>
                    </template>
                    <span>預算轉讓</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="tableData.length"
                :loading="!isBudgetsLoaded"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="openEditDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
            </span>
            <span class="text-h5">
              {{ $t(`common["Budget"]`) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.pointId"
                      :label="$t(`points['Name']`)"
                      :items="availablePoints"
                      item-text="name"
                      item-value="id"
                      :rules="rules.required"
                      @change="
                        getPointPeriods(editItem.pointId, false, true);
                        editItem.periodId = null;
                      "
                      required
                      :no-data-text="$t(`common['No Data']`)"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.periodId"
                      :label="$t(`periods['Name']`)"
                      :items="availableUnclosingPeriods"
                      item-text="name"
                      item-value="id"
                      :rules="rules.required"
                      :no-data-text="$t(`common['No Data']`)"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="editItem.organizationId"
                      :label="$t(`budgets['Organization']`)"
                      :items="availableOrganizations"
                      item-text="organizationName"
                      item-value="organizationId"
                      :rules="rules.required"
                      :no-data-text="$t(`common['No Data']`)"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.name"
                      :label="$t(`budgets['Name']`)"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="editItem.allocatedPoints"
                      type="number"
                      :label="$t(`budgets['AllocatedPoints']`)"
                      :rules="rules.budgets"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editItem.description"
                      outlined
                      :label="$t(`budgets['ApplicationDescription']`)"
                      :rules="rules.required"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveEditItem">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openApproveDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">審核預算</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="approveForm">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-radio-group
                      v-model="reviewStatus"
                      row
                      mandatory
                      class="large-radio"
                    >
                      <v-radio
                        label="核准"
                        :value="1"
                        :color="reviewStatus === 1 ? '#1867c0' : ''"
                      ></v-radio>
                      <v-radio
                        label="退回"
                        :value="2"
                        :color="reviewStatus === 2 ? '#1867c0' : ''"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="comment"
                      outlined
                      label="簽核意見"
                      :rules="rules.required"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!-- <v-btn color="blue darken-1" text @click="uploadImage">Upload</v-btn> -->
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeApproveDialog">
              {{ $t(`common['Cancel']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onApproveBudget()">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="openDetailDialog" fullscreen>
        <Ledger
          v-if="openDetailDialog"
          entity-type="AB"
          :budget-id="selectedBudgetId"
          @close="openDetailDialog = false"
        ></Ledger>
      </v-dialog>
      <v-dialog v-model="openTransferDialog" max-width="600px">
        <BudgetTransfer
          v-if="openTransferDialog"
          entity-type="AnnualBudget"
          :giver="transferFrom"
          :available-transfer-budgets="availableTransferBudgets"
          @close="onCloseTransferDialog"
          @done="onTransferredDone"
        ></BudgetTransfer>
        <!-- <v-card>
          <v-card-title>
            <span class="headline">
              預算轉讓：{{ transferFrom && transferFrom.name }}
            </span>
          </v-card-title>
          <v-card-subtitle class="pt-2">
            <span class="subtitle font-weight-light"> 請選擇要轉讓的預算 </span>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-form ref="transferForm">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="transferredBudgetId"
                      :items="availableTransferBudgets"
                      item-text="budgetName"
                      item-value="id"
                      :label="$t(`budgets['Name']`)"
                      :disabled="isActionDisabled"
                      :no-data-text="$t(`common['No Data']`)"
                      :rules="rules.required"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      v-model="transferFrom.transferPoints"
                      label="轉讓點數"
                      :disabled="true"
                      :rules="[
                        rules.maxTransfer(
                          transferFrom.availablePoints,
                          transferFrom.transferPoints
                        ),
                      ]"
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="onCloseTransferDialog">
              {{ $t(`common['Cancel']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onTransferBudget()">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card> -->
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { checkPermission } from "src/util/utils";

import {
  fetchPointSettings,
  fetchPointPeriods,
  fetchAnnualBudgets,
  fetchOrganizations,
} from "src/apis/fetchData";
import { updateAnnualBudget, approveAnnualBudget } from "src/apis/updateData";
// import { pointAssignment } from "src/apis/pointsCore";
import {
  generateArrayOfYears,
  generateArrayOfDays,
  generateArrayOfMonths,
} from "src/util/utils";
import Ledger from "./Widgets/Ledger.vue";
import { formatNumber } from "src/util/utils";
import BudgetTransfer from "./Widgets/BudgetTransfer.vue";
import Pagination from "../Campaigns/Widgets/Pagination.vue";

export default {
  components: { Ledger, BudgetTransfer, Pagination },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      isPeriodsLoaded: true,
      isBudgetsLoaded: true,
      isNew: false,
      openEditDialog: false,
      openApproveDialog: false,
      availableOrganizations: [],
      selectedOrganizationId: null,
      availablePoints: [],
      selectedPointId: null,
      currentPointId: null,
      availablePeriods: [],
      dAvailablePeriods: [],
      selectedPeriodId: null,
      currentPeriodId: null,
      tableData: [],
      tableHeaders: [
        {
          text: "ID",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t(`budgets["Organization"]`),
          sortable: true,
          value: "organizationName",
        },
        {
          text: this.$t(`common["Period"]`),
          sortable: true,
          value: "periodName",
        },
        {
          text: this.$t(`budgets["Name"]`),
          sortable: true,
          value: "budgetName",
        },
        // {
        //   text: "預算總額",
        //   sortable: true,
        //   filterable: false,
        //   align: "right",
        //   value: "allocatedPoints",
        // },
        {
          text: "原始預算",
          sortable: true,
          filterable: false,
          align: "right",
          value: "allocatedPoints",
        },
        {
          text: "實際預算",
          sortable: true,
          filterable: false,
          align: "right",
          value: "actualPoints",
        },
        {
          text: this.$t(`budgets["Execution Rate"]`),
          sortable: false,
          filterable: false,
          align: "right",
          value: "executionRate",
        },
        {
          text: "未分配",
          sortable: true,
          filterable: false,
          align: "right",
          value: "availablePoints",
        },
        {
          text: "已分配",
          sortable: true,
          filterable: false,
          align: "right",
          value: "usedPoints",
        },
        {
          text: this.$t(`common["Status"]`),
          sortable: true,
          filterable: false,
          value: "reviewStatus",
        },
        {
          text: this.$t(`common["Start Date"]`),
          sortable: true,
          filterable: false,
          value: "startTs",
        },
        {
          text: this.$t(`common["End Date"]`),
          sortable: true,
          filterable: false,
          value: "endTs",
        },
        {
          text: this.$t(`common["Created At"]`),
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        {
          text: this.$t(`common["Updated At"]`),
          sortable: true,
          filterable: false,
          value: "updatedAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
      search: null,
      defaultEditItem: {
        name: null,
        pointId: null,
        periodId: null,
      },
      editItem: {
        name: null,
        pointId: null,
        periodId: null,
      },
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        name: [(v) => !!v || this.$t(`common["Required"]`)],
        budgets: [
          (v) => !!v || this.$t(`common["Required"]`),
          (v) => v >= 0 || this.$t(`budgets["MinBudgetAllocate"]`),
        ],
        maxTransfer(maxValue, v) {
          return (v || "") <= maxValue || `點數最多為${maxValue}`;
        },
      },
      approveBudget: null, //審核的預算
      reviewStatus: null,
      comment: null,
      selectedBudgetId: null,
      openDetailDialog: false,
      isDetailLoading: false,
      // totalDetails: 0,
      // details: [],
      // tableDetailHeaders: [
      //   {
      //     text: "時間",
      //     sortable: true,
      //     filterable: false,
      //     value: "createdAt",
      //   },
      //   {
      //     text: "摘要",
      //     sortable: FontFaceSetLoadEvent,
      //     filterable: false,
      //     value: "transactionType",
      //   },
      //   {
      //     text: "收入",
      //     sortable: false,
      //     filterable: false,
      //     value: "income",
      //   },
      //   {
      //     text: "支出",
      //     sortable: false,
      //     filterable: false,
      //     value: "expenditure",
      //   },
      //   {
      //     text: "餘額",
      //     sortable: false,
      //     filterable: false,
      //     value: "balance",
      //   },
      //   {
      //     text: "備註",
      //     sortable: false,
      //     filterable: false,
      //     value: "description",
      //   },
      // ],
      transferFrom: {
        transferPoints: null,
        name: null,
      },
      // transferredBudgetId: null,
      openTransferDialog: false,
      availableTransferBudgets: [],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
      permissionScope: (state) => state.permissionScope,
    }),
    isActionDisabled() {
      return !(this.isPeriodsLoaded && this.isBudgetsLoaded);
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    availableUnclosingPeriods() {
      return this.dAvailablePeriods.filter((p) => p.closeStatus === 0);
    },
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:PointManage",
          "EditAnnualBudgets",
          true
        );
      }
      return false;
    },
    canAudit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:PointManage",
          "AuditAnnualBudgets",
          true
        );
      }
      return false;
    },
  },
  watch: {
    // pagination: {
    //   handler() {
    //     this.getDataFromApi();
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.getPointSettings();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getPointSettings(true);
    }
    this.getOrganizations();
    this.selectedOrganizationId = this.organizationId;
    this.years = generateArrayOfYears();
    this.days = generateArrayOfDays();
    this.months = generateArrayOfMonths();
  },
  methods: {
    gotoBudgetsPage(item) {
      this.$router.push({
        name: "Department Budget",
        query: {
          pointId: this.currentPointId,
          periodId: item.periodId,
          parentId: item.id,
          organizationId: item.organizationId,
        },
      });
    },
    getOrganizations() {
      fetchOrganizations(this.merchantId)
        .then((res) => {
          console.log("fetchOrganizations done", res);
          this.availableOrganizations = [...res.data];
          console.log(
            "this.availableOrganizations:" +
              JSON.stringify(this.availableOrganizations)
          );
        })
        .catch((e) => {
          console.log("fetchOrganizations failed", e);
        });
    },
    getPointSettings(searchBudgets) {
      //const merchantId = 1;
      let matchRouteQuery = false;

      // console.log("before ", this.pointSettings);
      if (this.pointSettings && this.pointSettings.length) {
        this.availablePoints = [...this.pointSettings];
        if (
          this.availablePoints &&
          this.availablePoints.length &&
          this.$route.query.pointId
        ) {
          const result = this.availablePoints.filter(
            (point) => point.id === parseInt(this.$route.query.pointId)
          );
          matchRouteQuery = result.length ? true : false;
        }
        this.selectedPointId = this.availablePoints.length
          ? matchRouteQuery
            ? parseInt(this.$route.query.pointId)
            : this.availablePoints[0].id
          : null;
        this.$nextTick(
          this.getPointPeriods(this.selectedPointId, searchBudgets)
        );
        return;
      }
      fetchPointSettings(this.merchantId)
        .then((res) => {
          console.log("fetchPointSettings done", res);
          this.availablePoints = [...res.data];
          console.log(
            "this.availablePoints:" + JSON.stringify(this.availablePoints)
          );
          if (
            this.availablePoints &&
            this.availablePoints.length &&
            this.$route.query.pointId
          ) {
            const result = this.availablePoints.filter((point) => {
              return point.id === parseInt(this.$route.query.pointId);
            });
            matchRouteQuery = result.length ? true : false;
          }
          this.selectedPointId = this.availablePoints.length
            ? matchRouteQuery
              ? parseInt(this.$route.query.pointId)
              : this.availablePoints[0].id
            : null;
          this.$store.commit("setPointSettings", res.data);
          this.$nextTick(
            this.getPointPeriods(this.selectedPointId, searchBudgets)
          );
        })
        .catch((e) => {
          console.log("fetchPointSettings failed", e);
        });
    },
    getPointPeriods(pid, searchBudgets, editDialog) {
      // const merchantId = 1;
      console.log("getPointPeriods:" + this.selectedPointId);
      console.log("getPointPeriods pid:" + pid, searchBudgets);
      this.isPeriodsLoaded = false;
      const pointId = pid ? pid : this.selectedPointId;
      fetchPointPeriods(this.merchantId, pointId)
        .then((res) => {
          console.log("fetchPointPeriods done", res);
          if (!editDialog) {
            this.availablePeriods = [...res.data];
          }
          this.dAvailablePeriods = [...res.data];

          let matchRouteQuery = false;
          if (
            this.availablePeriods &&
            this.availablePeriods.length &&
            this.$route.query.periodId
          ) {
            const result = this.availablePeriods.filter(
              (item) => item.id === parseInt(this.$route.query.periodId)
            );
            matchRouteQuery = result.length ? true : false;
          }

          if (searchBudgets) {
            this.selectedPeriodId = this.availablePeriods.length
              ? matchRouteQuery
                ? parseInt(this.$route.query.periodId)
                : null
              : null;
            this.$nextTick(this.getBudgets());
          }
        })
        .catch((e) => {
          console.log("fetchPointPeriods failed", e);
          this.$swal({
            text: "無法取得點數期別資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isPeriodsLoaded = true;
          this.currentPointId = this.selectedPointId;
        });
    },
    getBudgets($event, inputPointId, inputPeriodId) {
      // const merchantId = 1;
      console.log(
        "getBudgets:" + this.selectedPointId,
        this.organizationId,
        this.selectedOrganizationId
      );
      this.isBudgetsLoaded = false;
      this.pagination.page = 1;
      const pointId = inputPointId ? inputPointId : this.selectedPointId;
      const periodId = inputPeriodId ? inputPeriodId : this.selectedPeriodId;
      let query = {
        periodId,
        organizationId: this.selectedOrganizationId,
      };
      fetchAnnualBudgets(this.merchantId, pointId, query)
        .then((res) => {
          console.log("fetchAnnualBudgets done", res);
          this.tableData = res.data;
        })
        .catch((e) => {
          console.log("getBudgets failed", e);
          this.$swal({
            text: "無法取得期別預算資料",
            type: "error",
            showCancelButton: false,
            confirmButtonText: this.$i18n.t(`common["Confirm"]`),
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
          });
        })
        .finally(() => {
          this.isBudgetsLoaded = true;
          this.currentPeriodId = periodId;
        });
    },
    // getDataFromApi() {
    //   this.isDetailLoading = true;
    //   if (!this.openDetailDialog) {
    //     this.openDetailDialog = true;
    //   }
    //   this.getBudgetDetail().then((data) => {
    //     this.details = [...data.items];
    //     this.totalDetails = data.total;
    //     this.isDetailLoading = false;
    //   });
    // },
    // getBudgetDetail() {
    //   return new Promise((resolve, reject) => {
    //     const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
    //     // console.log("pagination", this.pagination);
    //     let query = {
    //       offset: (page - 1) * itemsPerPage || 0,
    //       limit: itemsPerPage,
    //     };
    //     let items = [];
    //     let total = 0;
    //     fetchAnnualBudgetDetail(this.merchantId, this.selectedBudgetId, query)
    //       .then((res) => {
    //         console.log("fetchAnnualBudgetDetail done:" + JSON.stringify(res));
    //         items = [...res.data.details];
    //         total = res.data.total;
    //       })
    //       .catch((e) => {
    //         console.log("fetchAnnualBudgetDetail failed", e);
    //         this.$swal({
    //           text: "無法取得期別預算帳本資料",
    //           type: "error",
    //           showCancelButton: false,
    //           confirmButtonText: this.$i18n.t(`common["Confirm"]`),
    //           customClass: {
    //             confirmButton: "btn bg-gradient-success",
    //             cancelButton: "btn bg-gradient-danger",
    //           },
    //         });
    //       })
    //       .finally(() => {
    //         if (sortBy.length === 1 && sortDesc.length === 1) {
    //           items = items.sort((a, b) => {
    //             const sortA = a[sortBy[0]];
    //             const sortB = b[sortBy[0]];

    //             if (sortDesc[0]) {
    //               if (sortA < sortB) return 1;
    //               if (sortA > sortB) return -1;
    //               return 0;
    //             } else {
    //               if (sortA < sortB) return -1;
    //               if (sortA > sortB) return 1;
    //               return 0;
    //             }
    //           });
    //         }

    //         // if (itemsPerPage > 0) {
    //         //   items = items.slice(
    //         //     (page - 1) * itemsPerPage,
    //         //     page * itemsPerPage
    //         //   );
    //         // }

    //         // if (page > 1) {
    //         //   let rawBegin = this.details.slice(0, (page - 1) * itemsPerPage);
    //         //   let rawEnd = this.details.slice(page * itemsPerPage, -1);
    //         //   this.items = rawBegin.concat(items, rawEnd);
    //         // }
    //         setTimeout(() => {
    //           resolve({
    //             items,
    //             total,
    //           });
    //         }, 1000);
    //       });
    //   });
    // },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      if (this.editItem.pointId !== this.selectedPointId) {
        this.getPointPeriods();
      }
      this.openEditDialog = false;
      this.editItem = Object.assign({}, this.defaultEditItem);
    },
    closeApproveDialog() {
      this.$refs.approveForm.resetValidation();
      this.$refs.approveForm.reset();
      this.openApproveDialog = false;
    },
    onApproveBudgetClick(item) {
      console.log("on approve click:" + JSON.stringify(item));
      this.approveBudget = item;
      this.openApproveDialog = true;
    },
    onApproveBudget() {
      console.log("approve budget:" + JSON.stringify(this.approveBudget));
      if (this.$refs.approveForm) {
        const valid = this.$refs.approveForm.validate();
        if (valid) {
          const data = {
            reviewer: this.ssoUser.userId,
            reviewStatus: this.reviewStatus,
            comment: this.comment,
          };
          approveAnnualBudget(
            this.merchantId,
            this.organizationId,
            this.approveBudget.id,
            data
          )
            .then((res) => {
              console.log("approveAnnualBudget done", res);
              this.approveBudget.reviewStatus = data.reviewStatus;
              this.approveBudget.comment = data.comment;
              if (data.reviewStatus === 1) {
                setTimeout(this.getBudgets(), 0);
              }
              this.comment = null;
              this.reviewStatus = null;
              this.openApproveDialog = false;
              this.$refs.approveForm.resetValidation();
              this.$refs.approveForm.reset();
            })
            .catch((e) => {
              console.log("approveAnnualBudget failed", e.response);
              let errmsg = "無法更新期別預算審核結果";
              if (e.response && e.response.data && e.response.data.message) {
                errmsg += "<br/>" + e.response.data.message;
              }
              this.isNew = false;
              this.showErrorAlert(errmsg);
            });
        } else {
          this.openApproveDialog(this.$i18n.t(`errors["Incorrect Fields"]`));
        }
      }
    },
    onEditItem(item) {
      // // 已審核過的就不能再編輯
      // if (item.reviewStatus !== 0) {
      //   return;
      // }
      if (item) {
        this.editItem = Object.assign({}, item);
        this.editItem.name = item.budgetName;
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.editItem.pointId = this.currentPointId;
        this.editItem.periodId = this.currentPeriodId;
        this.editItem.organizationId = this.organizationId;
        this.isNew = true;
      }
      // this.dAvailablePeriods = [...this.availablePeriods];
      this.openEditDialog = true;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      if (valid) {
        if (this.isNew) {
          this.editItem.applicantId = this.ssoUser.userId;
        }
        updateAnnualBudget(
          this.merchantId,
          this.editItem.organizationId,
          this.editItem.pointId,
          this.editItem
        )
          .then((res) => {
            console.log("updateAnnualBudget done", res);
            let self = this;
            this.selectedOrganizationId = this.editItem.organizationId;
            this.selectedPointId = this.editItem.pointId;
            this.selectedPeriodId = this.editItem.periodId;
            setTimeout(function () {
              // self.getBudgets();
              self.getPointPeriods(null, true, false);
              self.openEditDialog = false;
              self.$refs.editForm.resetValidation();
              self.$refs.editForm.reset();
              this.editItem = Object.assign({}, this.defaultEditItem);
            }, 10);
          })
          .catch((e) => {
            console.log("updateAnnualBudget failed", e);
            let errmsg = this.isNew ? "新增期別預算失敗" : "更新期別預算失敗";
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    showErrorAlert(message, inputTitle) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: inputTitle || title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    onCloseTransferDialog() {
      this.transferFrom = {
        transferPoints: null,
        name: null,
      };
      // this.transferredBudgetId = null;
      this.openTransferDialog = false;
    },
    onBudgetTransferClick(item) {
      // show dialog to select transfer budget
      // this.transferredBudgetId = null;
      this.transferFrom = {
        name: item.budgetName,
        id: item.id,
        availablePoints: item.availablePoints,
        transferPoints: item.availablePoints,
      };
      console.log("transfer:" + JSON.stringify(item));
      // 只能轉同一機關已審核且未結算的預算
      this.availableTransferBudgets = this.tableData.filter(
        (i) =>
          i.id !== item.id &&
          i.organizationId === item.organizationId &&
          i.closeStatus === 0 &&
          i.reviewStatus === 1
      );
      this.openTransferDialog = true;
    },
    onTransferredDone() {
      this.getBudgets();
      this.onCloseTransferDialog();
    },
    // onTransferBudget() {
    //   // api to tranasfer budget
    //   // const giver = this.tableData.filter((i) => i.id === this.transferFrom.id);
    //   const valid = this.$refs.transferForm.validate();
    //   if (valid) {
    //     const data = {
    //       giverEntityType: "AnnualBudget",
    //       giverEntityId: this.transferFrom.id,
    //       receiverEntityType: "AnnualBudget",
    //       receiverEntityId: this.transferredBudgetId,
    //       points: this.transferFrom.transferPoints,
    //       note: "預算轉讓",
    //     };
    //     console.log("data:", data);
    //     pointAssignment(this.merchantId, data)
    //       .then((res) => {
    //         console.log("pointAssignment done", res);
    //         this.getBudgets();
    //       })
    //       .catch((e) => {
    //         console.log("pointAssignment failed", e);
    //         let errmsg = `<b>${this.transferFrom.name}</b><br/>轉讓失敗`;
    //         if (
    //           e.response &&
    //           e.response.data &&
    //           e.response.data.error &&
    //           e.response.data.error.message
    //         ) {
    //           errmsg += "<br/>" + e.response.data.error.message;
    //         }
    //         this.showErrorAlert(errmsg, "預算轉讓失敗");
    //       })
    //       .finally(() => {
    //         this.onCloseTransferDialog();
    //       });
    //   } else {
    //     //this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`), "");
    //   }
    // },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
